import { manageStateSlice } from 'g2i-ngrx-utils';
import {
  Tracker,
  TrackerPosition as TrackerPosition,
  TrackingProvider,
  Vehicle,
  VehicleBookingSlotDisable,
  VehicleBookingSlotSummaries,
  VehicleGrant,
} from 'shared/api/asset/api-asset.response';
import { ServiceEventsAndUsers } from 'shared/api/logging/api-logging.responses';
import { Organization } from 'shared/api/organization/api-organization.response';

export const sharedState = manageStateSlice({
  listing: {
    scrollPos: 0,
    filters: {
      search: '',
      archived: false,
    }
  },
  vehicles: new Array<Vehicle>(),
  types: new Array<string>(),
  trackingPositions: {
    items: new Array<TrackerPosition>(),
  },
  timezones: new Array<string>(),
  trackers: new Array<Tracker>(),
  trackingProviders: new Array<TrackingProvider>(),
  vehicleGrants: new Array<VehicleGrant>(),
  vehicleGrantees: new Array<Organization>(),
  organizationKey: new Array<Organization>(),
  activityList: {
    filters: {
      startDate: null as string | null,
      endDate: null as string | null,
    },
    listItems: {} as ServiceEventsAndUsers,
  },
  vehicleBookingsList: {} as VehicleBookingSlotSummaries,
  vehicleBookingDisable: new Array<VehicleBookingSlotDisable>(),
})({
  vehicleListFilters: (state, payload: { search: string; archived: boolean }) => {
    state.listing.filters = payload;
  },
  listScroll: (state, payload: number) => {
    state.listing.scrollPos = payload;
  },
  vehicles: (state, payload: Vehicle[]) => {
    state.vehicles = payload;
  },
  trackers: (state, payload: Tracker[]) => {
    state.trackers = payload;
  },
  trackingProviders: (state, payload: TrackingProvider[]) => {
    state.trackingProviders = payload;
  },
  vehicleUpdate: (state, payload: Vehicle) => {
    state.vehicles.replace(payload, e => e.id);
  },
  listReset: (state) => {
    state.listing = sharedState.initialState.listing;
  },
  vehicleAdd: (state, payload: Vehicle) => {
    state.vehicles.push(payload);
  },
  vehicleGrants: (state, payload: VehicleGrant[]) => {
    state.vehicleGrants = payload;
  },
  vehicleGrantUpdate: (state, payload: VehicleGrant) => {
    state.vehicleGrants.replace(payload, e => e.id);
  },
  vehicleGrantAdd: (state, payload: VehicleGrant) => {
    state.vehicleGrants.push(payload);
  },
  timezones: (state, payload: string[]) => {
    state.timezones = payload;
  },
  trackerPositions: (state, payload: TrackerPosition[]) => {
    state.trackingPositions.items = payload;
  },
  vehicleGrant: (state, payload: VehicleGrant) => {
    state.vehicleGrants.replace(payload, e => e.id);
  },
  vehicleGrantees: (state, payload: Organization[]) => {
    state.vehicleGrantees = payload;
  },
  vehicleGrantee: (state, payload: Organization) => {
    state.vehicleGrantees.push(payload);
  },
  organizationKey: (state, payload: Organization[]) => {
    state.organizationKey = payload;
  },
  activityList: (state, payload: ServiceEventsAndUsers) => {
    state.activityList.listItems = payload;
  },
  activityListAppend: (state, payload: ServiceEventsAndUsers) => {
    state.activityList.listItems.serviceEvents.push(...payload.serviceEvents);
    state.activityList.listItems.userPersonProfiles.push(...payload.userPersonProfiles);
  },
  activityListFilters: (state, payload: { startDate: string | null; endDate: string | null }) => {
    state.activityList.filters = payload;
  },
  vehicleBookingSlot: (state, payload: VehicleBookingSlotSummaries) => {
    state.vehicleBookingsList = payload;
  },
  vehicleBookingSlotRemoval: (state, payload: VehicleBookingSlotDisable) => {
    state.vehicleBookingsList.vehicleBookingSlotSummaries.remove(e => e.bookingSlotId === payload.vehicleBookingSlot.id);
  },
});
